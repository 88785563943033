import {Component, ViewChild, ViewContainerRef} from '@angular/core';
import {ThemeService} from '@library/shared/_services/theme.service';
import {ClientService} from '@library/shared/_services/client.service';
import {IModalOptions, PageService} from '@library/shared/_services/page.service';
import {DomSanitizer} from '@angular/platform-browser';
import {ModalDialog} from '@library/shared/modal/modal.dialog';
import {take} from 'rxjs/operators';
import {AlertDialog} from '@library/shared/alert/alert.dialog';
import {RootConsumerAppComponent} from '@library/shared/_inherited/root-consumer-app.component';
import {SwUpdate} from '@angular/service-worker';
import {Router} from '@angular/router';
import {RcrtDataService} from './_services/rcrt-data.service';
import {Subscription} from 'rxjs';
import {InputDateCalendar} from '@library/shared/input/input-date-calendar';
import {InputDateTimeCalendar} from '@library/shared/input/input-date-time-calendar';
import {ThreadRightClickMenu} from '../../../nxto/src/app/threads/_components/thread-right-click.menu';
import {RightClickMenu} from '@library/shared/right-click/right-click.menu';

@Component({
    selector: 'app-root',
    template: `
        <rcrt-tos-header></rcrt-tos-header>
        <notification-dialog></notification-dialog>
        <progress-component icon="/assets/icons/48x48.png" 
                            [loading$]="pSvc.loading$"
                            [blocking$]="pSvc.blocking$"
        ></progress-component>
        <rcrt-header [player]="rdSvc.player$|async" [user]="rdSvc.user$|async"></rcrt-header>
        <div class="w-full h-full bg-gray-300 flex">
            <div class="mx-auto max-w-4xl bg-white w-full min-h-screen h-full">
                <router-outlet></router-outlet>
            </div>
        </div>
        <rcrt-footer *ngIf="public"></rcrt-footer>
        <ng-container #modalContainer></ng-container>
  `
})
export class AppComponent extends RootConsumerAppComponent {
    @ViewChild('modalContainer', {static: true, read: ViewContainerRef}) modalContainer: ViewContainerRef;
    public: boolean = true;

    constructor(
        public pSvc: PageService,
        private sanitizer: DomSanitizer,
        private swUpdate: SwUpdate,
        tSvc: ThemeService,
        router: Router,
        public cSvc: ClientService,
        public rdSvc: RcrtDataService
    ) {

        super(pSvc, tSvc, router);
        tSvc.setTheme(cSvc.client$.getValue()?.theme)

        this.pSvc.modal$.subscribe(
            (opts: IModalOptions) => {
                setTimeout(() => {
                    if (opts) {
                        let comp: any = ModalDialog;
                        switch (opts.component) {
                            case InputDateCalendar:
                            case InputDateTimeCalendar:
                                comp = opts.component;
                                break;
                        }
                        let ref: any = this.modalContainer.createComponent<any>(comp);
                        if (ref.instance?.init) {
                            ref.instance.init(opts);
                        }
                        if (!ref.instance.onClose) {
                            console.warn('Component is missing an onClose event.');
                        }
                        ref.instance.onClose?.pipe(take(1))
                            .subscribe(e => {
                                switch (comp) {
                                    case ThreadRightClickMenu:
                                    case RightClickMenu:
                                    case InputDateCalendar:
                                    case InputDateTimeCalendar:
                                        if (this.modalContainer.length) {
                                            this.modalContainer.remove(this.modalContainer.length - 1);
                                        }
                                        break;
                                    default:
                                        this.pSvc.clickEsc$.next(true);
                                        break;
                                }
                            });
                        if (opts?.onLoaded) {
                            try {
                                switch (opts.component) {
                                    case InputDateCalendar:
                                    case InputDateTimeCalendar:
                                        opts.onLoaded(ref.instance);
                                        break;
                                }
                            } catch (e) {
                                console.warn(e);
                            }
                        }
                    }
                });
            }
        );

        this.pSvc.clickEsc$
            .subscribe(
                r => {
                    if (r) {
                        if (this.modalContainer.length) {
                            this.modalContainer.remove(this.modalContainer.length - 1);
                        }
                    }
                }
            );

        this.pSvc.alert$
            .subscribe(
                (e: any) => {
                    if (e) {
                        let alert: any = {};
                        if (e.title || e.buttons || e.options || e.error?.title) {
                            alert = e.error?.title ? e.error : e;
                        } else if (e.error) {
                            let alert: any = {};
                            ['title', 'message', 'body', 'details'].forEach(p => {
                                alert[p] = (e.error[p] instanceof String && e.error[p].toString() !== 'undefined') ? e.error[p].toString() : '';
                            });
                            ['body', 'details'].forEach(prop => {
                                if (alert[prop]) {
                                    alert.message += ` ${alert[prop]}`;
                                }
                            });
                            ['name'].forEach(prop => {
                                if (alert[prop]) {
                                    alert.title += ` ${alert[prop]}`;
                                }
                            });
                            // console.warn('AFTER', alert);
                            alert = alert;
                            alert.title = alert.title || 'An Error Occurred';
                            alert.message = alert.message || 'Please try again. If the problem persists please contact support.';
                        }
                        if (!alert.title && e.statusText) {
                            alert.title = e.statusText;
                            alert.message = e.message || '';
                        }

                        this.pSvc.modal$.next({
                            component: AlertDialog,
                            buttons: alert.buttons,
                            label: alert.title,
                            onLoaded: (comp: AlertDialog) => {
                                comp.alert = alert;
                                if (e.html) {
                                    comp.html = this.sanitizer.bypassSecurityTrustHtml(e.html);
                                }
                                console.warn(e, alert);
                                this.pSvc.loading$.next(false);;
                                this.pSvc.blocking$.next(false);
                            }
                        });
                    }
                }
            );

        if (this.swUpdate?.isEnabled) {
            this.swUpdate.versionUpdates.subscribe((u) => {
                console.log('hey',u?.type);
                if  (u?.type === 'VERSION_READY') {
                    let last_update: number;
                    if (localStorage?.getItem('last_update') && !isNaN(Number(localStorage?.getItem('last_update')))) {
                        last_update = Number(localStorage?.getItem('last_update'));
                        console.log(last_update, new Date(last_update));
                    }
                    if (!last_update || (Date.now() - last_update) > 60000) {
                        localStorage.setItem('last_update', Date.now().toString());
                        window.location.reload();
                    } else {
                        this.pSvc.notification$.next({
                            title: 'New Version Detected!',
                            message: 'Click Reload to make sure you have the latest version! Not doing so could result in unpredictable app behavior.',
                            buttons: [
                                {
                                    label: 'Reload',
                                    click: () => {
                                        localStorage.setItem('last_update', Date.now().toString());
                                        window.location.reload();
                                    }
                                }
                            ]
                        })
                    }
                }
            });
        }

        let sub: Subscription;
        this.rdSvc.user$
            .subscribe(u => {
                sub?.unsubscribe();
                if (u?.id) {
                    sub = this.pSvc.state$
                        .subscribe((url) => {
                            if (url.match(/\/(account|public)/)) {
                                this.public = false;
                                this.rdSvc.setLastUrl(u,url);
                            }
                        });
                } else {
                    this.public = true;
                }
            });

    }


}

import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {CollegeRef, Email, ITeam, RcrtEventRef, RcrtUser} from '@nxt/model-rcrt';
import {DomSanitizer} from '@angular/platform-browser';
import {FooterButtonsComponent} from '@library/nxt/footer/footer-buttons.component';
import {IMenuItem} from '@nxt/model-core';
import {DatePipe} from '@angular/common';

@Component({
    standalone: true,
    selector: 'rct-email-reply',
    imports: [
        FooterButtonsComponent,
        DatePipe
    ],
    template: `
        <div class="p-3 bg-gray-100 space-y-2">
            <div class="text-right italic text-sm w-full">
                {{ email?.date | date:'EEE MMMM d | h:mm a' }}
            </div>
            <div class="bg-white p-2 rounded-md space-y-2">
                <div><span class="font-bold">From:</span> {{ email?.from?.email }}</div>
                <div><span class="font-bold">Subject:</span> {{ email?.subject }}</div>
            </div>
            <div class="text-right italic text-xs w-full">
                Embedded images are not displayed. Check your personal email to view contents.
            </div>
            <div class="bg-white p-2 rounded-md overflow-hidden" [innerHTML]="html"></div>
        </div>
        <footer-buttons-component [buttons]="buttons"></footer-buttons-component>
    `
})
export class RcrtEmailReply implements OnChanges {
    @Output() onClose: EventEmitter<Email> = new EventEmitter<Email>();
    @Input() player: RcrtUser;
    @Input() user: RcrtUser;
    @Input() team: ITeam;
    @Input() eRef: RcrtEventRef;
    @Input() cRef: CollegeRef;
    @Input() email: Email;
    html: any;
    buttons: IMenuItem[] = [
        {
            label: 'Close',
            click: () => {
                this.onClose.emit();
            }
        },
        {
            label: 'Reply',
            class: 'btn-dark',
            click: () => {
                this.onClose.emit(this.email);
            }
        }
    ];


    constructor(
        private sanitizer: DomSanitizer
    ) {
    }

    ngOnChanges(changes?: SimpleChanges) {
        if (this.email) {
            if (this.email.html) {
                this.html = this.sanitizer.bypassSecurityTrustHtml(this.email.html);
            } else if (this.email.body) {
                this.html = this.sanitizer.bypassSecurityTrustHtml(this.email.body.replace(/\n/g,'<br/>'));
            }
        }

    }
}

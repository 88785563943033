import {Component, ElementRef, EventEmitter, Input, Output, SimpleChanges} from '@angular/core';
import {CommonModule} from '@angular/common';
import { olm, RcrtUser
} from '@nxt/model-rcrt';
import {IconsComponent} from '@library/shared/icons/icons.component';
import {ConsumerScrollableList} from '@library/consumer/list/consumer-scrollable-list';
import {RcrtRatingsAvatar} from '../../account/_components/ratings/rcrt-ratings-avatar';
import {RcrtResultsMetrics} from '../rcrt-results-metrics';
import {PageService} from '@library/shared/_services/page.service';
import {ConsumerFireService} from '@library/consumer/_services/consumer.fire.service';
import {Router, RouterLink} from '@angular/router';
import {RcrtDataService} from '../../_services/rcrt-data.service';
import {PipesModule} from '@library/shared/_pipes/pipes';
import {IMenuItem} from '@nxt/model-core';
import {takeUntil} from 'rxjs/operators';
import {OnDestroyPage} from '@library/shared/_inherited/ondestroy.page';
import {BehaviorSubject} from 'rxjs';
import {LocalStorageService} from '@library/shared/_services/local-storage.service';

@Component({
    selector: 'rcrt-player-dropdown',
    standalone: true,
    imports: [
        CommonModule, IconsComponent, ConsumerScrollableList,
        RcrtRatingsAvatar, RcrtResultsMetrics, PipesModule, RouterLink, IconsComponent
    ],
    template: `
        <div (click)="loadProfile()" *ngIf="user?.id">
            <button  class="btn-clear btn-sm" *ngIf="!rdSvc.isPlayer">
                <img class="h-8 w-8 bg-gray-100 rounded-full ring-1 ring-white" [src]="user?.image?.src||'/assets/no-image-user.webp'">
                <img class="h-8 w-8 bg-gray-100 rounded-full ring-1 ring-white" *ngIf="player?.id" [src]="player?.image?.src||'/assets/no-image-user.webp'">
            </button>
            <button class="btn-clear btn-sm" *ngIf="rdSvc.isPlayer">
                <img class="h-8 w-8 bg-gray-100 rounded-full ring-1 ring-white" [src]="player?.image?.src||'/assets/no-image-user.webp'">
            </button>
        </div>
        <div *ngIf="user===null">
            <button routerLink="/login/get-started" class="btn-dark btn-sm mr-4">
                Sign Up/In
            </button>
        </div>

<!--        <div *ngIf="show$|async" class="{{ menuState }} absolute top-16 right-0 w-400 max-w-[100vw] z-10 bg-accent rounded-t-md shadow-lg ring-1 ring-gray-900/5">-->
<!--            <icon name="heroicon-solid-chevron-up" class="h-8 w-8 text-accent right-0 -top-1.5 absolute"></icon>-->
<!--            <div class="pt-1 pr-8 w-full flex place-content-end">-->
<!--                <button class="btn-clear btn-xs hover:text-white" (click)="hide()">-->
<!--                    <icon name="heroicon-outline-x" class="h-6 w-6"></icon>-->
<!--                </button>-->
<!--            </div>-->
<!--            <div class="p-4 pt-0 grid grid-cols-2 gap-x-2 w-full">-->
<!--                <div>-->
<!--                    <div class="flex space-x-2">-->
<!--                        <img class="h-8 w-8 bg-gray-100 rounded-full ring-1 ring-white" [src]="user?.image?.src||'/assets/no-image-user.webp'">-->
<!--                        <h3 class="text-sm font-medium leading-6 text-white">-->
<!--                            {{user.name}}-->
<!--                        </h3>-->
<!--                    </div>-->
<!--                    <div class="mt-6 flow-root">-->
<!--                        <div class="-my-2">-->
<!--                            <a *ngFor="let option of options"-->
<!--                               [href]="option.routerLink"-->
<!--                               (click)="hide();"-->
<!--                               [title]="option.label"-->
<!--                               class="flex place-items-center gap-x-4 py-2 text-sm font-semibold text-gray-900 hover:text-white"-->
<!--                            >-->
<!--                                <icon *ngIf="option.icon?.name" [name]="option.icon?.name" class="h-4 w-4"></icon>-->
<!--                                <span>{{option.label}}</span>-->
<!--                            </a>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--            <div class="bg-black p-1 rounded-b-md">-->
<!--                <a  routerLink="/login/logout" title="Log Out" class="btn-clear btn-sm text-accent hover:text-white">-->
<!--                    <icon name="heroicon-outline-arrow-circle-right" class="h-4 w-4 mr-2"></icon>-->
<!--                    Log Out-->
<!--                </a>-->
<!--            </div>-->
<!--        </div>-->
<!--        -->
<!--        <ng-template let-item="item" let-items="items" let-i="i" #itemTemplate>-->
<!--            <li class="rcrt-list-item flex border-b border-gray-200" (click)="handleClick(item['_url'])">-->
<!--                <div class="w-full flex justify-between p-1">-->
<!--                    <div class="flex space-x-3">-->
<!--                        <div class="flex shrink-0 -space-x-1">-->
<!--                            <img class="h-8 w-8 bg-gray-100 rounded-full ring-1 ring-white" [src]="item['_src']||'/assets/empty.png'" alt="">-->
<!--                            <div *ngIf="item['_unread']" class="bg-red-700 h-3 w-3 text-white text-xxs flex place-content-center rounded-full ml-1">&nbsp;</div>-->
<!--                        </div>-->
<!--                        <div class="flex flex-col">-->
<!--                            <div class="text-sm font-semibold text-gray-900">-->
<!--                                {{ item['_label'] }}-->
<!--                            </div>-->
<!--                            <div class="text-xs text-gray-500">-->
<!--                                {{ item['_description'] }}-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <div class="flex flex-col text-right text-xxs italic text-gray-500 uppercase whitespace-nowrap">-->
<!--                        <div>{{ item.date | dateAgo }}</div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </li>-->
<!--        </ng-template>-->
    `
})
export class RcrtPlayerDropdown extends OnDestroyPage {
    @Output() onShow: EventEmitter<any> = new EventEmitter();
    @Input() label: string;
    @Input() player: RcrtUser;
    @Input() user: RcrtUser;

    show$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    placement: 'top-right' = 'top-right';
    menuState: string = 'opacity-0 -translate-y-1';
    show: boolean = false;
    opts: any;
    options: IMenuItem[] = [];

    constructor(
        public pSvc: PageService,
        public rdSvc: RcrtDataService,
        private fSvc: ConsumerFireService,
        private router: Router,
        private lSvc: LocalStorageService
    ) {
        super();
        this.opts = this.fSvc.loadAllOpts(olm);
    }

    async loadProfile() {
        // console.log('this.lSvc.globalState?.lastPage', this.lSvc.globalState?.lastPage);
        if (this.lSvc.globalState?.lastPage && !this.lSvc.globalState?.lastPage.match(/login|logout/)) {
            await this.router.navigateByUrl(this.lSvc.globalState?.lastPage);
        } else {
            await this.router.navigateByUrl(`/account`);
        }

    }

    async handleClick(path: string) {
        if (path) {
            this.hide();
            await this.router.navigateByUrl(path);
        } else {
            this.pSvc.notification$.next({
                title: 'Oops!',
                message: `Couldn't find the related item!`
            });
        }
    }

    hide() {
        this.show = false;
        this.showHideMenu();
    }

    showHideMenu() {
        switch (this.show) {
            case true:
                this.show$.next(true);
                setTimeout(() => {
                    this.menuState = 'opacity-100 translate-y-0 transition ease-out duration-200';
                }, 200);
                this.onShow.emit();
                break;
            default:
                this.menuState = 'opacity-0 -translate-y-1 transition ease-in duration-150';
                setTimeout(() => {
                    this.show$.next(false);
                }, 200);
                break;
        }
    }

}
